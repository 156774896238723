@keyframes DrivingKeyframes {
    0%,
    100% {
        transform: translate(0px, 0px);
    }
    10% {
        transform: translate(20px, -6px);
    }
    20% {
        transform: translate(-6px, -10px);
    }
    30% {
        transform: translate(-16px, -16px);
    }
    40% {
        transform: translate(8px, 0px);
    }
    50% {
        transform: translate(12px, 10px);
    }
    60% {
        transform: translate(12px, -20px);
    }
    70% {
        transform: translate(-4px, -8px);
    }
    80% {
        transform: translate(-10px, 4px);
    }
    90% {
        transform: translate(4px, 2px);
    }
}
@keyframes Flashingframes {
    0%,
    35%,
    65%,
    100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}
@keyframes ResizingKeyframes {
    50% {
        transform: scale(0.5);
    }
}

.EffectPrisoned {
    position: absolute;
    background: black;
    top: 0;
    left: 0;
    width: 10%;
    height: 100%;
}

.EffectPrisoned:nth-child(2) {
    left: 20%;
}
.EffectPrisoned:nth-child(3) {
    left: 40%;
}
.EffectPrisoned:nth-child(4) {
    left: 60%;
}
.EffectPrisoned:nth-child(5) {
    left: 80%;
}
