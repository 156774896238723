html,
body,
#root {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    touch-action: none;
    user-select: none;
}
/* 
body svg:not(:root) {
    overflow: visible;
} */

.MuiInputBase-root {
    background: white;
}
body .MuiOutlinedInput-adornedEnd {
    padding-inline-end: 0;
}
body .MuiInputAdornment-positionEnd {
    margin-inline-start: 0;
}

#client-snackbar {
    white-space: pre-wrap;
    word-break: break-word;
}
#client-snackbar + div {
    margin: 8px;
    margin-inline-start: auto;
    margin-inline-end: 0;
}

#snackbarContainer > div {
    max-width: 460px;
    pointer-events: none;
}
@media (max-width: 599.95px) {
    /* Can't add a class to the div itself */
    #snackbarContainer > div {
        /* Make sure actions are visible in mobile. */
        max-width: calc(100% - 100px);
        min-width: 200px;
        top: 0;
    }
}
.MuiSnackbar-root {
    pointer-events: initial;
}

.pcr-swatches {
    margin-bottom: 0.6em;
    justify-content: center;
}
